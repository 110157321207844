<template>
    <div class="d-flex flex-column w-100">
        <div class="d-flex flex-row align-center">
            <span>Фильтр по категориям</span>
            <v-select
                v-model="filter.year"
                :items="years"
                label="Год"
                :disabled="loading"
                class="ml-5"
                @change="fetchKits"
            ></v-select>
            <v-select
                v-model="filter.subject"
                :items="subjects"
                label="Предмет"
                :disabled="loading"
                class="ml-5"
                @change="fetchKits"
            ></v-select>
            <v-select
                v-model="filter.grade"
                :items="grades"
                label="Класс"
                :disabled="loading"
                class="ml-5"
                @change="fetchKits"
            ></v-select>
        </div>
        <div v-if="!allFilteresFilled" class="d-flex flex-row align-center">
            <p class="grey--text text-center w-100 my-10">Укажите значения в каждом фильтре</p>
        </div>
        <v-progress-circular
            v-else-if="loading"
            :size="50"
            :color="$const.color.primary"
            indeterminate
            class="mx-auto my-10"
        ></v-progress-circular>
        <template v-else>
            <div class="d-flex justify-end w-100">
                <v-btn
                    dark
                    elevation="0"
                    :color="$const.color.primary"
                    :loading="loading"
                    @click="dialogs.createNewKit = true"
                >+ Набор</v-btn>
            </div>
            <div v-if="!items.length" class="d-flex flex-row align-center">
                <p class="grey--text text-center w-100 my-10">По указанным категориям не создано еще ни одного набора.<br>Для создания нового набора нажмите кнопку "+ Набор".</p>
            </div>
            <v-card
                v-for="item in items"
                :key="item.version"
                class="d-flex w-100 mt-4"
            >
                <v-card-text>
                    <v-container class="pa-0">
                        <v-row>
                            <v-col cols="1" class="d-flex align-center">
                                <v-icon>mdi-package-variant-closed</v-icon>
                            </v-col>
                            <v-col cols="2" class="d-flex align-center">
                                <div>Создано {{ timestampToDate(item.created_at * 1000) }}</div>
                            </v-col>
                            <v-col cols="2" class="d-flex align-center">
                                <v-chip>v{{ item.version }}</v-chip>
                            </v-col>
                            <v-col cols="5" class="d-flex align-center">
                                <v-chip v-if="item.is_current" color="green" dark>Текущая</v-chip>
                            </v-col>
                            <v-col cols="2" class="d-flex justify-end align-center">
                                <v-menu
                                    bottom
                                    left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-horizontal</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            v-for="(action, i) in actions"
                                            :key="i"
                                            link
                                            :disabled="action.isDisabled && action.isDisabled(item)"
                                        >
                                            <v-list-item-title @click="action.handler(item)">{{ action.title }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </template>

        <v-dialog v-model="dialogs.description" persistent max-width="600px">
            <v-card v-if="selectedItem">
                <v-card-title>
                    Описание версии
                </v-card-title>
                <v-card-text>
                    <h3 class="mb-2">Для внутреннего использования</h3>
                    <p>{{ selectedItem.description_private || '...' }}</p>
                    <h3 class="mb-2">Для публичного использования</h3>
                    <p>{{ selectedItem.description_public || '...' }}</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="0" :color="$const.color.primary" dark @click.prevent.stop="dialogs.description = false">
                        Закрыть
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogs.setKitAsCurrent" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    Подтвердите установку версии как текущей
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="0" :color="$const.color.primary" dark @click.prevent.stop="dialogs.setKitAsCurrent = false">
                        Отмена
                    </v-btn>
                    <v-btn :color="$const.color.error" outlined @click.prevent.stop="setKitAsCurrentBtnHandler">
                        Установить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogs.removeKit" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    Подтвердите удаление набора
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="0" :color="$const.color.primary" dark @click.prevent.stop="dialogs.removeKit = false">
                        Отмена
                    </v-btn>
                    <v-btn :color="$const.color.error" outlined @click.prevent.stop="removeKitBtnHandler">
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogs.createNewKit" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    Введите описание для сборки нового набора
                </v-card-title>
                <v-card-text>
                    <v-textarea
                        v-model="form.description_private"
                        label="Описание для внутреннего использования"
                        class="w-100"
                    ></v-textarea>
                    <v-textarea
                        v-model="form.description_public"
                        label="Описание для публичного использования"
                        hint="Внимание! Этот текст будет виден пользователю."
                        class="w-100"
                    ></v-textarea>
                    <div class="mt-5">
                        <span>Укажите какой характер изменений в новой версии по сравнению с предыдущей:</span>
                        <v-radio-group v-model="form.minor_changes">
                            <v-radio
                                label="Только исправления ошибок"
                                :value="false"
                            ></v-radio>
                            <v-radio
                                label="Другие изменения/Первая сборка"
                                :value="true"
                            ></v-radio>
                        </v-radio-group>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="0" :color="$const.color.primary" dark @click.prevent.stop="dialogs.createNewKit = false">
                        Отмена
                    </v-btn>
                    <v-btn
                        outlined
                        :color="$const.color.primary"
                        :disabled="form.minor_changes === null"
                        @click.prevent.stop="createNewKitBtnHandler"
                    >
                        Создать
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import StringHelper from '@/plugins/string'
import { JOB_SERVER } from '@/servers'

const getNewForm = () => ({
    description_private: '',
    description_public: '',
    minor_changes: null,
    year: null,
    subject: null,
    subject_transliterated: null,
    grade: null
})

export default {
    data () {
        return {
            loading: false,
            filter: {
                year: (new Date()).getFullYear(),
                subject: null,
                grade: null
            },
            dialogs: {
                createNewKit: false,
                removeKit: false,
                setKitAsCurrent: false,
                description: false
            },
            selectedItem: null,
            form: getNewForm(),
            items: []
        }
    },
    computed: {
        years () {
            const curYear = (new Date()).getFullYear()
            const startYear = 2022
            const result = []
            let year = startYear
            do {
                result.push(year++)
            } while (year <= curYear)
            return result
        },
        grades () {
            return (new Array(11)).fill(null).map((item, index) => index + 1)
        },
        subjects () {
            return this.$store.state.task.subjects
                .map(s => ({ text: s.text, value: s.value }))
        },
        allFilteresFilled () {
            return this.filter.year && this.filter.subject && this.filter.grade
        },
        actions () {
            return [
                {
                    title: 'Описание',
                    handler: (kitItem) => {
                        this.selectedItem = kitItem
                        this.dialogs.description = true
                    }
                },
                {
                    title: 'Сделать текущей',
                    handler: (kitItem) => {
                        this.selectedItem = kitItem
                        this.dialogs.setKitAsCurrent = true
                    },
                    isDisabled: (kitItem) => !!kitItem.is_current
                },
                {
                    title: 'Скачать',
                    handler: (kitItem) => {
                        window.open(`${JOB_SERVER}/${kitItem.path}`)
                    }
                },
                {
                    title: 'Удалить',
                    handler: (kitItem) => {
                        this.selectedItem = kitItem
                        this.dialogs.removeKit = true
                    }
                }
            ]
        }
    },
    created () {
        this.filter.year = this.years[this.years.length - 1]
    },
    methods: {
        async createNewKitBtnHandler () {
            await this.createNewKit();
            this.fetchKits();
        },
        async removeKitBtnHandler () {
            const result = await this.removeKit()
            if (!result) {
                alert('Не удалось удалить набор.')
                return
            }
            this.items = this.items.filter(item => item.id !== this.selectedItem.id)
        },
        async setKitAsCurrentBtnHandler () {
            await this.setKitAsCurrent();
            this.fetchKits();
        },
        async fetchKits () {
            if (!this.allFilteresFilled || this.loading) { return }
            this.loading = true

            try {
                const { success, data, error } = await this.$store.dispatch('content_kit/list', {
                    pagination: 0,
                    sort: '-created_at',
                    filter: this.filter,
                    fields: ['created_at', 'description_private', 'description_public', 'grade', 'path', 'subject', 'is_current', 'year', 'version'].join(',')
                });
                if (!success) {
                    console.error(error)
                    alert('Ошибка загрузки данных.')
                    return
                }
                this.items = data.items
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
            }
        },
        async createNewKit () {
            if (!this.allFilteresFilled || this.loading) { return }
            this.loading = true
            this.dialogs.createNewKit = false
            try {
                this.form.year = this.filter.year
                this.form.subject = this.filter.subject
                this.form.subject_transliterated = StringHelper.transliterate(this.form.subject)?.toLowerCase()
                this.form.grade = this.filter.grade
                
                const {success, error} = await this.$store.dispatch('content_kit/create', this.form);
                if (!success) {
                    console.error(error)
                    
                    switch (error) {
                        case 'Tasks not found':
                            alert('По указанным параметрам не найдено ни одного задания.')
                            break;
                        case 'Jobs not found':
                            alert('По указанным параметрам не найдено ни одной работы.')
                            break;
                        default:
                            alert('Неизвестная ошибка создания сборки. Подробную информацию можете найти в консоли разработчика.')
                            break;
                    }
                }
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
                this.form = getNewForm()
            }
        },
        async removeKit () {
            if (!this.selectedItem || this.loading) { return }
            this.loading = true
            this.dialogs.removeKit = false
            let result = false
            try {
               
                const { success } = await this.$store.dispatch('content_kit/delete', { id: this.selectedItem.id });
                result = success
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
            }
            return result
        },
        async setKitAsCurrent () {
            if (!this.selectedItem || this.loading) { return }
            this.loading = true
            this.dialogs.setKitAsCurrent = false
            try {
               
                const { success, error } = await this.$store.dispatch('content_kit/update', { id: this.selectedItem.id, payload: { set_as_current: true }});
                if (!success) {
                    console.error(error)
                    alert('Неизвестная ошибка создания сборки. Подробную информацию можете найти в консоли разработчика.')
                }
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
            }
        },
        timestampToDate (timestamp) {
            const date = new Date(timestamp)
            return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
        }
    }
}
</script>